import React from 'react'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import Breadcrumbs, { Path } from '../../../../components/molecules/Breadcrumbs'
import style from './styles.module.scss'

export type TenantSalesReportSummaryHeaderProps = {
  breadcrumbs: Path[]
  storeName: string
  storeCode: string
} & React.StyledProps

const TenantSalesReportSummaryHeader: React.StyledFC<
  TenantSalesReportSummaryHeaderProps
> = ({
  className,
  breadcrumbs,
  storeName,
  storeCode,
}: TenantSalesReportSummaryHeaderProps) => {
  return (
    <Box className={className}>
      <Breadcrumbs className={style.breadcrumbs} paths={breadcrumbs} />
      <Box className={style.store}>
        <Typography display="inline" variant="h1" className={style.title}>
          日次管理
        </Typography>
        <Typography
          className={clsx(style.store, style.name)}
          display="inline"
          variant="h2"
        >
          {storeName}
        </Typography>
        <Typography
          className={clsx(style.store, style.code)}
          color="textSecondary"
          display="inline"
          variant="h2"
        >
          {storeCode}
        </Typography>
      </Box>
    </Box>
  )
}

export default TenantSalesReportSummaryHeader
