import React, { ReactElement } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { AuthProvider } from '../context/auth.context'
import { ToastProvider } from '../context/toast.context'
import CompleteEditCalculation from '../pages/CompleteEditCalculation'
import EditCalculationDSL from '../pages/EditCalculationDSL'
import EditOCRFormat from '../pages/EditOCRFormat'
import EditReadItem from '../pages/EditReadItem'
import Login from '../pages/Login'
import LoginAction from '../pages/LoginAction'
import LoginSentEmail from '../pages/LoginSentEmail'
import PagesMembersList from '../features/members/pages/List'
import PagesMembersCreate from '../features/members/pages/Create'
import PagesMembersUpdate from '../features/members/pages/Update'
import Root from '../pages/Root'
import StartEditCalculation from '../pages/StartEditCalculation'
import Store from '../pages/Store'
import StoreSalesReportSummary from '../features/storeSalesReportSummary/pages'
import Stores from '../pages/Stores'
import TenantDetail from '../pages/TenantDetail'
import TenantSalesReport from '../features/tenantSalesReport/pages/TenantSalesReport'
import PagesTenantSalesReportSummary from '../features/tenantSalesReportSummary/pages'
import Tenants from '../pages/Tenants'
import LoginRoute from './loginRoute'
import { PathTemplate } from './path'
import PrivateRoute from './privateRoute'
import TenantCreate from '../pages/TenantCreate'
import TenantUpdate from '../pages/TenantUpdate'
import Profile from '../features/members/pages/Profile'
import PagesTenantOwnerCompanyList from '../features/tenantOwnerCompany/pages/List'
import PagesTenantOwnerCompanyCreate from '../features/tenantOwnerCompany/pages/Create'
import PagesTenantOwnerCompanyUpdate from '../features/tenantOwnerCompany/pages/Update'

function AppRouter(): ReactElement {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Root />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.login}
                element={
                  <LoginRoute>
                    <Login />
                  </LoginRoute>
                }
              />
              <Route
                path={PathTemplate.loginSentEmail}
                element={
                  <LoginRoute>
                    <LoginSentEmail />
                  </LoginRoute>
                }
              />
              <Route
                path={PathTemplate.loginAction}
                element={
                  <LoginRoute>
                    <LoginAction />
                  </LoginRoute>
                }
              />
              <Route
                path={PathTemplate.profile}
                element={
                  <PrivateRoute showSidebar>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.stores}
                element={
                  <PrivateRoute showSidebar>
                    <Stores />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.store}
                element={
                  <PrivateRoute showSidebar>
                    <Store />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenant}
                element={
                  <PrivateRoute showSidebar>
                    <TenantDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenants}
                element={
                  <PrivateRoute showSidebar>
                    <Tenants />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.storeSalesReportSummaries}
                element={
                  <PrivateRoute showSidebar>
                    <StoreSalesReportSummary />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.members}
                element={
                  <PrivateRoute showSidebar>
                    <PagesMembersList />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.membersCreate}
                element={
                  <PrivateRoute>
                    <PagesMembersCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.membersUpdate}
                element={
                  <PrivateRoute>
                    <PagesMembersUpdate />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenantSalesReportSummaries}
                element={
                  <PrivateRoute showSidebar>
                    <PagesTenantSalesReportSummary />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenantOwnerCompaniesUpdate}
                element={
                  <PrivateRoute>
                    <PagesTenantOwnerCompanyUpdate />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenantOwnerCompanies}
                element={
                  <PrivateRoute showSidebar>
                    <PagesTenantOwnerCompanyList />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.tenantOwnerCompaniesCreate}
                element={
                  <PrivateRoute>
                    <PagesTenantOwnerCompanyCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path={PathTemplate.startEditCalculation}
                element={<StartEditCalculation />}
              />
              <Route
                path={PathTemplate.createTenants}
                element={<TenantCreate />}
              />
              <Route
                path={PathTemplate.updateTenants}
                element={<TenantUpdate />}
              />
              <Route
                path={PathTemplate.editReadItem}
                element={<EditReadItem />}
              />
              <Route
                path={PathTemplate.editOCRFormat}
                element={<EditOCRFormat />}
              />
              <Route
                path={PathTemplate.editCalculationDSL}
                element={<EditCalculationDSL />}
              />
              <Route
                path={PathTemplate.completeEditCalculation}
                element={<CompleteEditCalculation />}
              />
              <Route
                path={PathTemplate.tenantSalesReport}
                element={<TenantSalesReport />}
              />
            </Routes>
          </QueryParamProvider>
        </ToastProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default AppRouter
