import { TenantOwnerCompany } from 'src/slices/services/api'
import { FilterParam } from './components/FilterBar'

type Payload = {
  networkData?: TenantOwnerCompany[]
  totalCount?: number
  filterData?: FilterParam
}

export type Action = {
  type: 'ADD_NETWORK_DATA' | 'UPDATE_SEARCH_CONDITION' | 'LOAD_MORE'
  payload?: Payload
}

export type TenantOwnerCompanyListState = {
  allShownData: TenantOwnerCompany[]
  isLoading: boolean
  isLoadingMore: boolean
  currentCount: number
  totalCount?: number
  hasMoreContent: boolean
  hasFilterParam?: boolean
}

export const INITIAL_STATE: TenantOwnerCompanyListState = {
  allShownData: [],
  isLoading: true,
  isLoadingMore: false,
  currentCount: 0,
  totalCount: 0,
  hasMoreContent: false,
  hasFilterParam: false,
}

export const reducerFunc = (
  state: TenantOwnerCompanyListState,
  action: Action
): TenantOwnerCompanyListState => {
  let newState: TenantOwnerCompanyListState
  switch (action.type) {
    case 'ADD_NETWORK_DATA':
      {
        const originalContentCount = action.payload?.networkData?.length
        const addedData =
          action.payload?.networkData?.filter((data) => {
            return !state.allShownData.find((d) => d.id === data.id)
          }) || []
        let nextCurrentCount = state.currentCount
        if (addedData.length > 0 && typeof originalContentCount === 'number') {
          nextCurrentCount += originalContentCount
        }
        const totalCount = action.payload?.totalCount
        let hasMoreContent = false
        if (totalCount && nextCurrentCount < totalCount) {
          hasMoreContent = true
        }

        newState = {
          ...state,
          allShownData: [...state.allShownData, ...addedData],
          currentCount: nextCurrentCount,
          hasMoreContent,
          totalCount,
          isLoading: false,
          isLoadingMore: false,
        }
      }
      break
    case 'UPDATE_SEARCH_CONDITION':
      {
        const hasFilterParam =
          Object.values(action.payload?.filterData ?? {}).filter(
            (value) => value !== undefined
          ).length > 0
        newState = {
          ...state,
          hasFilterParam,
          allShownData: [],
          currentCount: 0,
          totalCount: 0,
          hasMoreContent: false,
          isLoading: true,
        }
      }
      break
    case 'LOAD_MORE':
      newState = {
        ...state,
        isLoadingMore: true,
      }
      break
    default:
      newState = {
        ...state,
      }
  }
  return newState
}
