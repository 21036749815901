import React, { memo, useCallback, useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import Modal from '../../../../components/molecules/Modal'
import MembersCreateTable, {
  MembersFormType,
} from '../../components/CreateTable'
import {
  MemberPostRequest,
  RoleEnum,
  Store as EntityStore,
} from '../../../../slices/services/api'
import memberUpdateStyle from '../Update/styles.module.scss' // MemberCreateとMemberUpdateのstyleがほぼ同じなので、Updateのものを流用している。
import listStyles from '../../../../styles/list-styles.module.scss'
import { ToastTriggerContext } from '../../../../context/toast.context'
import Button from '../../../../components/atoms/Button'

export type TemplatesMembersCreateProps = {
  onClickConfirmClose: () => void
  onCreateMembers: (members: MemberPostRequest[]) => void
  stores: EntityStore[]
  selfRole: RoleEnum
  orgCode: string
}

const TemplatesMembersCreate: React.StyledFC<TemplatesMembersCreateProps> =
  memo(
    ({
      onClickConfirmClose,
      onCreateMembers,
      stores,
      selfRole,
      orgCode,
    }: TemplatesMembersCreateProps) => {
      const toastContext = useContext(ToastTriggerContext)
      const [showCancelModal, setShowCancelModal] = useState(false)
      const [duplicationEmails, setDuplicationEmails] = useState<string[]>([])
      const [duplicationMessage, setDuplicationMessage] = useState<string>('')
      const formMethods = useForm<MembersFormType>({
        defaultValues: { members: [{ email: '', name: '' }] },
      })

      const onClickClosePage = () => {
        setShowCancelModal(true)
      }
      const onClickCancelClose = () => {
        setShowCancelModal(false)
      }

      const getSelfDuplication = useCallback(
        (members: MembersFormType['members']) => {
          const emails = members.map((member) => member.email)
          return emails.filter(
            (email, index) => emails.indexOf(email) !== index
          )
        },
        []
      )

      const onSubmit = (formData: MembersFormType) => {
        if (!formData.members || formData.members.length === 0) {
          toastContext.sendToast({
            variant: 'error',
            title: `ユーザーを追加してください `,
          })
          return
        }
        const noAssociatedStoreUserExist = formData.members.some((member) => {
          return (
            member.assignedStores.allChecked === false &&
            member.assignedStores.checkListValues.length === 0
          )
        })
        if (noAssociatedStoreUserExist) {
          toastContext.sendToast({
            variant: 'error',
            title: `店舗が紐づいていないユーザーがいます`,
          })
          return
        }

        const duplication = getSelfDuplication(formData.members)
        if (duplication.length > 0) {
          setDuplicationEmails(duplication)
          setDuplicationMessage('登録メールが重複しています。削除してください')
          return
        }
        const members: MemberPostRequest[] = []
        formData.members.forEach((member) => {
          members.push({
            name: member.name,
            email: member.email,
            role: member.role,
            hasAllStoresPermission: member.assignedStores.allChecked,
            assignedStoreIds: member.assignedStores.checkListValues,
          })
        })
        onCreateMembers(members)
      }

      return (
        <>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Box className={listStyles.container}>
                <Typography className={memberUpdateStyle.title} variant="h1">
                  ユーザー新規追加
                </Typography>
                <Box
                  className={clsx(
                    memberUpdateStyle.mainContent,
                    memberUpdateStyle.tableContainer
                  )}
                >
                  <MembersCreateTable
                    orgCode={orgCode}
                    stores={stores}
                    selfRole={selfRole}
                  />
                </Box>
                <Box className={memberUpdateStyle.bottom}>
                  <Button
                    title="キャンセルする"
                    size="medium"
                    color="primary"
                    onClick={onClickClosePage}
                  />
                  <Button
                    title="追加する"
                    size="medium"
                    color="secondary"
                    type="submit"
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
          <Modal
            open={showCancelModal}
            title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
            agreeButtonTitle="このまま中断する"
            disagreeButtonTitle="設定に戻る"
            onClickAgree={onClickConfirmClose}
            onClickDisagree={onClickCancelClose}
          />
          <Modal
            open={duplicationEmails && duplicationEmails.length > 0}
            disableBackdropClick={false}
            title={duplicationMessage}
            agreeButtonTitle="ok"
            onClose={() => setDuplicationEmails([])}
            onClickAgree={() => setDuplicationEmails([])}
          >
            <Box>
              {duplicationEmails.map((email) => (
                <Typography
                  variant="body1"
                  className={memberUpdateStyle.duplicateList}
                  key={email}
                >
                  {email}
                </Typography>
              ))}
            </Box>
          </Modal>
        </>
      )
    }
  )

TemplatesMembersCreate.displayName = 'TemplatesMembersCreate'
export default TemplatesMembersCreate
