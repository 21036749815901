import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { VIEWABLE_CONTRACT_DATE_CODES } from 'src/domain/org'
import { TenantDetail } from '../../../../slices/services/api'
import Card from '../../../../components/atoms/Card'
import styles from './styles.module.scss'
import commonStyles from '../../common.module.scss'
import CenteringLoading from '../../../../components/molecules/CenteringLoading'
import Button from '../../../../components/atoms/Button'

export type TenantDetailTabProps = {
  tenantDetail?: TenantDetail
  isLoaded: boolean
  orgCode: string
  onStartEdit?: () => void
}

const TenantDetailTab: React.StyledFC<TenantDetailTabProps> = ({
  tenantDetail,
  isLoaded,
  orgCode,
  onStartEdit,
}: TenantDetailTabProps) => {
  return (
    <Box className={styles.root}>
      {!isLoaded ? (
        <CenteringLoading />
      ) : (
        <Box className={commonStyles.container}>
          <Box className={commonStyles.leftColumn}>
            <Box className={commonStyles.leftCard}>
              <Card className={commonStyles.cardTitle} title="テナント情報">
                <Box className={styles.content}>
                  <Typography component="h3" className={styles.label}>
                    代表者氏名
                  </Typography>
                  <p className={styles.value}>
                    {tenantDetail?.staffName || '-'}
                  </p>
                </Box>
                <Box className={styles.content}>
                  <Typography component="h3" className={styles.label}>
                    電話番号
                  </Typography>
                  <p className={styles.value}>
                    {tenantDetail?.staffTel || '-'}
                  </p>
                </Box>
                {VIEWABLE_CONTRACT_DATE_CODES.includes(orgCode) && (
                  <>
                    <Box className={styles.cardDivider} />
                    <Box className={styles.datesContainer}>
                      <Box>
                        <Typography className={styles.dateLabel} component="h3">
                          契約開始日
                        </Typography>
                        <p className={styles.value}>
                          {tenantDetail?.contractStartDate || '-'}
                        </p>
                      </Box>
                      <Box>
                        <Typography className={styles.dateLabel} component="h3">
                          契約終了日
                        </Typography>
                        <p className={styles.value}>
                          {tenantDetail?.contractEndDate || '-'}
                        </p>
                      </Box>
                    </Box>
                  </>
                )}
              </Card>
            </Box>
          </Box>
          <Box className={commonStyles.rightColumn}>
            <Box className={commonStyles.rightCard}>
              <Card
                className={commonStyles.cardTitle}
                title="管理者メモ（テナントごと）"
              >
                <p className={styles.note}>{tenantDetail?.note || '-'}</p>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={commonStyles.footer}>
        <Divider className={commonStyles.divider} />
        <Box className={commonStyles.footerButtonWrapper}>
          <Button
            className={commonStyles.footerButton}
            color="primary"
            size="medium"
            title="設定を変更する"
            onClick={onStartEdit}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TenantDetailTab
