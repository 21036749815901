import React from 'react'
import clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormControlLabel, Typography } from '@mui/material'
import styles from './styles.module.scss'
import SearchTextField from '../../molecules/SearchTextField'
import CheckBox from '../../atoms/CheckBox'

const SEARCH_FIELD_NAME = 'tenantSearchText'
const FILTER_READY_NAME = 'ready'
const FILTER_NOT_READY_NAME = 'notReady'
const FILTER_CLOSED_NAME = 'closed'

export type FilterForm = {
  [SEARCH_FIELD_NAME]: string
  [FILTER_READY_NAME]: boolean
  [FILTER_NOT_READY_NAME]: boolean
  [FILTER_CLOSED_NAME]: boolean
}

export type TenantFilterBarProps = {
  tenantCount: number
} & React.StyledProps

const TenantFilterBar: React.StyledFC<TenantFilterBarProps> = ({
  className,
  tenantCount,
}: TenantFilterBarProps) => {
  const formMethods = useFormContext<FilterForm>()
  const { control, reset } = formMethods

  return (
    <Box className={clsx(className, styles.container)}>
      <Typography component="p" className={styles.count}>
        {`${tenantCount}テナント`}
      </Typography>
      <Box>
        <SearchTextField
          name={SEARCH_FIELD_NAME}
          type="text"
          placeholder="名前、コード"
          onClickClear={() => reset({ [SEARCH_FIELD_NAME]: '' })}
        />
      </Box>
      <FormControlLabel
        className={styles.label}
        control={
          <Controller
            name={FILTER_NOT_READY_NAME}
            control={control}
            render={({ field }) => (
              <CheckBox
                {...field}
                name={FILTER_NOT_READY_NAME}
                shape="rectangle"
                disableRipple
                isChecked={field.value ?? false}
              />
            )}
          />
        }
        label="利用開始前"
      />
      <FormControlLabel
        className={styles.label}
        control={
          <Controller
            name={FILTER_READY_NAME}
            control={control}
            render={({ field }) => (
              <CheckBox
                {...field}
                name={FILTER_READY_NAME}
                shape="rectangle"
                disableRipple
                isChecked={field.value ?? false}
              />
            )}
          />
        }
        label="利用中"
      />
      <FormControlLabel
        className={styles.label}
        control={
          <Controller
            name={FILTER_CLOSED_NAME}
            control={control}
            render={({ field }) => (
              <CheckBox
                {...field}
                name={FILTER_CLOSED_NAME}
                shape="rectangle"
                disableRipple
                isChecked={field.value ?? false}
              />
            )}
          />
        }
        label="利用停止"
      />
      <Typography
        component="span"
        className={styles.clear}
        onClick={() => reset()}
      >
        すべてクリア
      </Typography>
    </Box>
  )
}

export default TenantFilterBar
