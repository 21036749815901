import React, { ChildrenProps } from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import styles from './styles.module.scss'

export type CardProps = {
  className?: string
  title: string
  width?: number
} & React.StyledProps &
  ChildrenProps

const Card: React.StyledFC<CardProps> = ({
  className,
  title,
  children,
  width,
}: CardProps) => {
  return (
    <Box className={clsx(className, styles.root)} style={{ width }}>
      <Typography className={styles.title} variant="h2">
        {title}
      </Typography>
      <Box className={styles.body}>{children}</Box>
    </Box>
  )
}

export default Card
