import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Path, { OrgPathParams } from 'src/routes/path'
import { useApiTenantOwnerCompany } from 'src/hooks/useApiTenantOwnerCompany'
import { TenantOwnerCompany } from 'src/slices/services/api'
import TemplatesTenantOwnerCompanyList from 'src/features/tenantOwnerCompany/templates/List'
import { Path as BreadcrumbsPath } from 'src/components/molecules/Breadcrumbs'
import useAppTitle from 'src/hooks/useAppTitle'
import { INFINITE_SCROLL_CONTENT_LOADING_NUM } from 'src/domain/config'
import { FilterParam } from '../../components/FilterBar'

type SearchParam = {
  searchText: string
  active?: boolean
  loadOffsetNum: number
}

const PagesTenantOwnerCompanyList: React.FC = (): ReactElement => {
  useAppTitle('取引企業一覧')
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()
  const { getTenantOwnerCompanies } = useApiTenantOwnerCompany()
  const [searchParam, setSearchParam] = useState<SearchParam>({
    searchText: '',
    active: undefined,
    loadOffsetNum: 0,
  })
  const [tenantOwnerCompanies, setTenantOwnerCompanies] = useState<
    TenantOwnerCompany[]
  >([])
  const [totalCount, setTotalCount] = useState<number>()

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantOwnerCompanies(
        orgCode,
        searchParam.loadOffsetNum,
        INFINITE_SCROLL_CONTENT_LOADING_NUM,
        searchParam.searchText,
        searchParam.active
      )
      setTenantOwnerCompanies(res.data?.tenantOwnerCompanies || [])
      if (typeof res.data?.count === 'number') {
        setTotalCount(res.data?.count)
      }
    }
    executeApi()
  }, [getTenantOwnerCompanies, orgCode, searchParam])

  const handleLoadMore = (offset: number) => {
    setSearchParam({ ...searchParam, loadOffsetNum: offset })
  }

  const handleChangeFilterParams = (filterData: FilterParam) => {
    setSearchParam({
      searchText: filterData.searchText || '',
      active: filterData.active,
      loadOffsetNum: 0,
    })
  }

  const handleGoToCreate = () => {
    navigate(Path.tenantOwnerCompaniesCreate(orgCode))
  }

  const handleGoToUpdate = () => {
    navigate(Path.tenantOwnerCompaniesUpdate(orgCode))
  }

  const breadCrumbPaths: BreadcrumbsPath[] = [
    {
      index: 0,
      title: '取引企業',
      to: Path.tenantOwnerCompanies(orgCode),
    },
  ]

  return (
    <TemplatesTenantOwnerCompanyList
      tenantOwnerCompanies={tenantOwnerCompanies}
      totalCount={totalCount}
      breadCrumbPaths={breadCrumbPaths}
      onChangeFilterParams={handleChangeFilterParams}
      onGoToCreate={handleGoToCreate}
      onGoToUpdate={handleGoToUpdate}
      loadMore={handleLoadMore}
    />
  )
}
export default PagesTenantOwnerCompanyList
