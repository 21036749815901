import React, {
  memo,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Box, IconButton, Table, TableBody } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import style from './styles.module.scss'
import { RoleEnum, Store as EntityStore } from '../../../../slices/services/api'
import {
  AppTableHead,
  AppTableRow,
  ChildCell,
  IconCell,
  InputCell,
  SelectCell,
  TextCell,
} from '../../../../components/tables'
import { CheckFormType } from '../../../../components/molecules/CheckBoxListWithFilter'
import MemberAssignedStoreCheck from '../../../../components/molecules/MemberAssignedStoreCheck'
import { roleOptionsForAdminStoreManager } from '../../type'
import Icon from '../../../../components/atoms/Icon'
import { InitialRowsState, reducerFunc } from '../../../../utils/tableReducer'
import {
  InvalidInputErrorMessage,
  RegexEmail,
} from '../../../../utils/regularExpression'
import { getRoleOptions } from '../../function'
import { AllAssignedStoresNotAvailableRoles } from '../../constants'

interface MemberFormType {
  name: string
  email: string
  role: RoleEnum
  assignedStores: {
    checkListValues: string[]
    allChecked: boolean
  }
}

export interface MembersFormType {
  members: MemberFormType[]
}

export type MembersCreateTableProps = {
  orgCode: string
  stores: EntityStore[]
  selfRole: RoleEnum
} & React.StyledProps

const MembersCreateTable: React.StyledFC<MembersCreateTableProps> = memo(
  ({ orgCode, stores, selfRole }: MembersCreateTableProps) => {
    const [rowState, dispatch] = useReducer(
      reducerFunc,
      InitialRowsState(1),
      (init) => init
    )
    const [roles, setRoles] = useState<RoleEnum[]>([RoleEnum.Viewer])
    const formMethods = useFormContext<MembersFormType>()

    const addRow = () => {
      dispatch({ type: 'ADD_ROW' })
    }

    const allStoreIds = useCallback(() => {
      return stores.map((store) => store.id)
    }, [stores])

    const removeRow = (key: number) => {
      dispatch({ type: 'REMOVE_ROW', key })
      formMethods.unregister(`members.${key}`)
    }

    useEffect(() => {
      const subscription = formMethods.watch((value, { name }) => {
        if (!name?.endsWith('role') || !value.members || !roles) return

        // roleが変更されたときに、フォームのassignedStoresを強制変更する処理
        const splitName = name.split('.')
        const formIndex = Number(splitName[1])
        const formMembers: MemberFormType[] = value.members as MemberFormType[]
        const nextRole = value.members[formIndex]?.role
        const previousAssignedStoreAllChecked =
          formMembers[formIndex].assignedStores?.allChecked
        if (
          previousAssignedStoreAllChecked &&
          !!nextRole &&
          AllAssignedStoresNotAvailableRoles.includes(nextRole)
        ) {
          formMethods.setValue(`members.${formIndex}.assignedStores`, {
            checkListValues: selfRole === RoleEnum.Admin ? [] : allStoreIds(),
            allChecked: false,
          })
        }
        if (nextRole === RoleEnum.Admin) {
          formMethods.setValue(`members.${formIndex}.assignedStores`, {
            checkListValues: [],
            allChecked: true,
          })
        }
        const roleArr = formMembers.map((member) => member.role)
        setRoles(roleArr)
      })
      return () => subscription.unsubscribe()
    }, [formMethods, formMethods.watch, roles, allStoreIds, selfRole])

    return (
      <Box>
        <Table>
          <AppTableHead
            columns={[
              { title: '権限' },
              { title: '氏名', labeledMessage: '必須' },
              {
                title: 'メールアドレス',
                labeledMessage: '必須',
                message: '※ 重複不可',
              },
              { title: '担当店舗' },
            ]}
            hideCellWidth={16}
          />
          <TableBody>
            {rowState.keys.map((key) => {
              // NOTE このroleはformMemberから用いても値は同じだが、formMethods.watchで変更した値を変更するには、setRolesでrenderingをかけないといけないので、rolesを用いている。
              const role = roles[key]
              const disableAssignedStore = role === RoleEnum.Admin
              const disabledAllCheck =
                AllAssignedStoresNotAvailableRoles.includes(role)

              // NOTE: defaultCheckListは、初期値に加え、MemberAssignedStoreCheckはTextCellから置き換わるときに適用される。
              let defaultCheckList: CheckFormType
              if (disabledAllCheck) {
                defaultCheckList = {
                  checkListValues:
                    selfRole === RoleEnum.Admin ? [] : allStoreIds(),
                  allChecked: false,
                }
              } else {
                defaultCheckList = {
                  allChecked: true,
                  checkListValues: [],
                }
              }
              return (
                <AppTableRow key={key}>
                  <IconCell
                    className={style.icon}
                    icon="minus"
                    onClick={() => removeRow(key)}
                  />
                  <SelectCell
                    name={`members.${key}.role`}
                    width={180}
                    options={
                      selfRole === RoleEnum.AdminStoreManager
                        ? roleOptionsForAdminStoreManager
                        : getRoleOptions(orgCode)
                    }
                    defaultValue={
                      selfRole === RoleEnum.AdminStoreManager
                        ? RoleEnum.EditorSalesReportManager
                        : RoleEnum.Viewer
                    }
                  />
                  <InputCell
                    name={`members.${key}.name`}
                    placeholder="氏名"
                    width={290}
                    required
                  />
                  <InputCell
                    name={`members.${key}.email`}
                    placeholder="メールアドレス"
                    width={300}
                    validations={[
                      {
                        regex: RegexEmail,
                        message: InvalidInputErrorMessage,
                      },
                    ]}
                    required
                  />
                  {disableAssignedStore ? (
                    <TextCell width={340} text="全ての店舗" />
                  ) : (
                    <ChildCell>
                      <MemberAssignedStoreCheck
                        width={340}
                        stores={stores}
                        defaultCheckList={defaultCheckList}
                        formName={`members.${key}.assignedStores`}
                        defaultTitle="選択してください"
                        becomeErrorIfEmpty
                        disabledAllCheck={disabledAllCheck}
                      />
                    </ChildCell>
                  )}
                </AppTableRow>
              )
            })}
          </TableBody>
        </Table>
        <Box className={style.addButtonWrapper}>
          <IconButton className={style.button} onClick={addRow}>
            <Icon icon="plus" />
          </IconButton>
        </Box>
      </Box>
    )
  }
)

MembersCreateTable.displayName = 'MembersCreateTable'
export default MembersCreateTable
