import React from 'react'
import { Box } from '@mui/material'
import CircularProgress from '../../atoms/CircularProgress'
import styles from './styles.module.scss'

const LoadMore: React.FC = () => {
  return (
    <Box className={styles.root}>
      <CircularProgress className={styles.icon} />
    </Box>
  )
}
export default LoadMore
