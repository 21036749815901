import React, { useReducer, useCallback, useState } from 'react'
import { Box, TableBody, Table, Typography } from '@mui/material'
import { TenantOwnerCompanyPostRequest } from 'src/slices/services/api'
import Modal from 'src/components/molecules/Modal'
import { AppTableHead, AppTableAddRowButton } from 'src/components/tables'
import listStyles from 'src/styles/list-styles.module.scss'
import { reducerFunc, INITIAL_STATE } from '../../createReducer'
import Footer from '../../components/Footer'
import CreateTableRow, {
  TenantOwnerCompanyCreateType,
  ValidateFormTypeCreate,
} from '../../components/CreateTableRow'
import styles from './styles.module.scss'

export type TemplatesTenantOwnerCompanyCreateProps = {
  onSubmit: (data: TenantOwnerCompanyPostRequest[]) => void
  onGoBack: () => void
}

const TemplatesTenantOwnerCompanyCreate: React.StyledFC<
  TemplatesTenantOwnerCompanyCreateProps
> = ({ onSubmit, onGoBack }: TemplatesTenantOwnerCompanyCreateProps) => {
  const [createTenantOwnerCompanyState, dispatch] = useReducer(
    reducerFunc,
    INITIAL_STATE
  )
  const [showCancelModal, setShowCancelModal] = useState(false)

  const handleSubmit = () => {
    if (
      createTenantOwnerCompanyState.isAllValid &&
      createTenantOwnerCompanyState.duplicatedCodes.length === 0
    ) {
      const createdData = Object.values(
        createTenantOwnerCompanyState.createdData
      )
      // NOTE: codeとnameには実際にはvalidationが走っているので
      // codeとnameがundefinedになることはない
      onSubmit(createdData as TenantOwnerCompanyPostRequest[])
    }
    dispatch({ type: 'SUBMIT_WITH_INVALID_FORM' })
  }

  const handleAddRow = () => {
    dispatch({ type: 'ADD_ROW' })
  }

  const handleRemoveRow = (rowNum: number) => {
    dispatch({ type: 'REMOVE_ROW', payload: { removeRowNum: rowNum } })
  }

  const handleChangeFormValue = (formData: TenantOwnerCompanyCreateType) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { formData } })
  }

  const handleValidate = useCallback((validStatus: ValidateFormTypeCreate) => {
    dispatch({
      type: 'UPDATE_EACH_VALID_STATUS',
      payload: {
        eachValidStatus: {
          rowNum: validStatus.rowNum,
          isValid: validStatus.isValid,
        },
      },
    })
  }, [])

  const handleGoBack = () => {
    if (Object.values(createTenantOwnerCompanyState.createdData).length > 0) {
      setShowCancelModal(true)
    } else {
      onGoBack()
    }
  }

  return (
    <>
      <Box>
        <Box className={listStyles.container}>
          <Typography className={styles.title} variant="h1">
            取引企業新規追加
          </Typography>
          <Box className={styles.mainContent}>
            <Table stickyHeader>
              <AppTableHead
                columns={[
                  {
                    title: '企業コード',
                    labeledMessage: '必須',
                    message: '※ 重複不可',
                    width: 180,
                  },
                  {
                    title: '企業名称',
                    labeledMessage: '必須',
                    width: 360,
                  },
                ]}
                hideCellWidth={72}
              />
              <TableBody>
                {createTenantOwnerCompanyState.rows.map((rowNum) => (
                  <CreateTableRow
                    rowNum={rowNum}
                    key={rowNum}
                    onChangeFormValue={handleChangeFormValue}
                    onValidate={handleValidate}
                    onRemoveRow={handleRemoveRow}
                    showAllError={createTenantOwnerCompanyState.showAllError}
                  />
                ))}
              </TableBody>
            </Table>
            <AppTableAddRowButton onClick={handleAddRow} />
          </Box>
        </Box>
        <Box className={styles.bottom}>
          <Footer
            onCreate={handleSubmit}
            onGoBack={handleGoBack}
            isAllValid={createTenantOwnerCompanyState.isAllValid}
            duplicatedCodes={createTenantOwnerCompanyState.duplicatedCodes}
          />
        </Box>
      </Box>
      <Modal
        open={showCancelModal}
        title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
        agreeButtonTitle="このまま中断する"
        disagreeButtonTitle="設定に戻る"
        onClickAgree={onGoBack}
        onClickDisagree={() => setShowCancelModal(false)}
      />
    </>
  )
}

export default TemplatesTenantOwnerCompanyCreate
