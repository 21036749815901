import React, { memo, useEffect, useReducer } from 'react'
import { Box, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import Button from '../../../../components/atoms/Button'
import Breadcrumbs, {
  BreadcrumbsProps,
} from '../../../../components/molecules/Breadcrumbs'
import MemberFilterBar from '../../components/FilterBar'
import MembersTable from '../../components/Table'
import { reducerFunc, INITIAL_STATE } from '../../reducer'
import {
  Member,
  RoleEnum,
  Store as EntityStore,
} from '../../../../slices/services/api'
import style from './styles.module.scss'
import listStyles from '../../../../styles/list-styles.module.scss'
import { CheckFormType } from '../../../../components/molecules/CheckBoxListWithFilter'

export type TemplatesMembersListProps = {
  members: Member[]
  breadCrumbPaths: BreadcrumbsProps['paths']
  stores: EntityStore[]
  onClickCreateMember?: () => void
  onClickUpdateMember?: () => void
}

const TemplatesMembersList: React.StyledFC<TemplatesMembersListProps> = memo(
  ({
    members,
    stores,
    breadCrumbPaths,
    onClickCreateMember,
    onClickUpdateMember,
  }: TemplatesMembersListProps) => {
    const [memberState, dispatch] = useReducer(reducerFunc, INITIAL_STATE)
    const filterformMethods = useForm()

    const handleSearchTextUpdated = (searchText: string) => {
      dispatch({
        type: 'UPDATE_SEARCH_TEXT',
        payload: { searchParamText: searchText },
      })
    }
    const handleRoleUpdated = (role: RoleEnum) => {
      dispatch({
        type: 'UPDATE_SEARCH_ROLE',
        payload: { role },
      })
    }
    const handleAssignedStoresUpdated = (assignedStores: CheckFormType) => {
      dispatch({
        type: 'UPDATE_ASSIGNED_STORE',
        payload: { assignedStores },
      })
    }

    useEffect(() => {
      dispatch({ type: 'UPDATE_MEMBERS', payload: { allMembers: members } })
    }, [members])

    return (
      <Box className={listStyles.container}>
        <Breadcrumbs className={style.breadcrumb} paths={breadCrumbPaths} />
        <Typography className={style.title} variant="h1">
          ユーザー管理
        </Typography>

        <Box className={style.filterWrapper}>
          <Box className={style.header}>
            <Box className={style.count}>{memberState.count} 人 </Box>
            <FormProvider {...filterformMethods}>
              <form>
                <MemberFilterBar
                  onSearchTextUpdated={handleSearchTextUpdated}
                  onRoleUpdated={handleRoleUpdated}
                  onAssignedStoresUpdated={handleAssignedStoresUpdated}
                  stores={stores}
                />
              </form>
            </FormProvider>
          </Box>
        </Box>

        <Box className={style.mainContent}>
          <MembersTable members={memberState.filteredMembers} />
        </Box>

        <Box className={style.bottom}>
          <Button
            title="ユーザー追加"
            size="medium"
            color="secondary"
            onClick={onClickCreateMember}
          />
          <Button
            disabled={memberState.filteredMembers?.length === 0}
            title="ユーザー編集"
            size="medium"
            color="primary"
            onClick={onClickUpdateMember}
          />
        </Box>
      </Box>
    )
  }
)

TemplatesMembersList.displayName = 'TemplatesMembers'
export default TemplatesMembersList
