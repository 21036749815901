import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, FormControlLabel, Typography } from '@mui/material'
import PopupChild from 'src/components/molecules/PopupChild'
import CheckBoxListWithFilter from 'src/components/molecules/CheckBoxListWithFilter'
import {
  getReportStatusTitle,
  isReportStatusBeforeReported,
  statusCheckOptions,
} from 'src/domain/reportStatus'
import clsx from 'clsx'
import {
  consistencyCheckOptions,
  getConsistencyTitle,
} from 'src/domain/consistency'
import {
  DefaultFilterValues,
  FilterOptions,
  DateRange,
  FormType,
  FormNameEnum,
} from 'src/features/salesReport/type'
import { useMount } from 'react-use'
import dayjs from 'dayjs'
import Tooltip from 'src/components/atoms/Tooltip'
import DatePicker from 'src/components/atoms/DatePicker'
import SearchTextField from 'src/components/molecules/SearchTextField'
import CheckBox from 'src/components/atoms/CheckBox'
import style from './styles.module.scss'

export type SalesReportsFilterProps = {
  date: DateRange
  totalCount: number
  defaultFilterValues: DefaultFilterValues
  onChangeDate: (date: DateRange) => void
  onChangeFilterParams: (filterOptions: FilterOptions) => void
  onChangeFilterForMismatch: (value: boolean) => void
}

const MAX_DATE = dayjs().format('YYYY-MM-DD')

const SalesReportsFilter: React.FC<SalesReportsFilterProps> = ({
  date,
  totalCount,
  defaultFilterValues,
  onChangeDate,
  onChangeFilterParams,
  onChangeFilterForMismatch,
}: SalesReportsFilterProps) => {
  const [reportStatusTitle, setReportStatusTitle] = useState<string>('全て')
  const [consistencyTitle, setConsistencyTitle] = useState<string>('全て')

  const formInitialValues: FormType = {
    reportStatus: {
      allChecked: defaultFilterValues.reportStatus.length === 0,
      checkListValues: defaultFilterValues.reportStatus,
    },
    consistency: {
      allChecked: defaultFilterValues.consistencyEnums.length === 0,
      checkListValues: defaultFilterValues.consistencyEnums,
    },
    tenantCodeOrName: defaultFilterValues.searchText,
  }

  const formMethods = useForm<FormType>({
    defaultValues: formInitialValues,
  })

  const statusCheckOptionsAfterReported = statusCheckOptions.filter(
    (option) => !isReportStatusBeforeReported(option.value)
  )

  const hasErrorSearchTextField = formMethods.formState.errors.tenantCodeOrName

  const reportStatusList = formMethods.watch(
    FormNameEnum.reportStatus
  ).checkListValues
  const consistencyList = formMethods.watch(
    FormNameEnum.consistency
  ).checkListValues

  const getFilterOptions = (data: FormType): FilterOptions => {
    return {
      reportStatus: data.reportStatus.checkListValues,
      consistency: data.consistency.checkListValues,
      tenantCodeOrName: data.tenantCodeOrName,
    }
  }

  const updateCheckboxTitle = (options: FilterOptions) => {
    if (options.consistency.length === 0) {
      setConsistencyTitle('全て')
    } else if (options.consistency.length === 1) {
      setConsistencyTitle(getConsistencyTitle(options.consistency[0]))
    } else {
      setConsistencyTitle(`${options.consistency.length}つ選択中`)
    }

    if (options.reportStatus.length === 0) {
      setReportStatusTitle('全て')
    } else if (options.reportStatus.length === 1) {
      setReportStatusTitle(getReportStatusTitle(options.reportStatus[0]))
    } else {
      setReportStatusTitle(`${options.reportStatus.length}つ選択中`)
    }
  }

  useMount(() => {
    const filterOptions: FilterOptions = getFilterOptions(formInitialValues)
    updateCheckboxTitle(filterOptions)
  })

  useEffect(() => {
    const subscription = formMethods.watch((value) => {
      const filterOptions: FilterOptions = getFilterOptions(value as FormType)

      if (hasErrorSearchTextField) {
        // NOTE: 検索欄が30文字以上の場合はエラーを表示して、他のフィルターが変更されてもAPIは叩かない
        return
      }

      onChangeFilterParams(filterOptions)
      updateCheckboxTitle(filterOptions)
    })
    return () => subscription.unsubscribe()
  })

  const handleChangeStartDate = (startDate: string) => {
    if (hasErrorSearchTextField) return
    onChangeDate({
      ...date,
      start: startDate,
    })
  }

  const handleChangeEndDate = (endDate: string) => {
    if (hasErrorSearchTextField) return
    onChangeDate({
      ...date,
      end: endDate,
    })
  }

  const handleChangeFilterForMismatch = (value: boolean) => {
    if (hasErrorSearchTextField) return
    onChangeFilterForMismatch(value)
  }

  const handleClickClear = () => {
    formMethods.setValue(FormNameEnum.tenantCodeOrName, '')
    const updatedFormValues = formMethods.getValues()
    const updatedFilterParam = getFilterOptions(updatedFormValues)
    onChangeFilterParams(updatedFilterParam)
  }

  return (
    <Box className={style.filterContainer}>
      <Box className={style.totalCount}>
        <Typography variant="h2" component="p" display="inline">
          {totalCount}
        </Typography>
        <Typography variant="subtitle1" component="p" display="inline">
          &thinsp;件
        </Typography>
      </Box>
      <FormProvider {...formMethods}>
        <Tooltip
          open={Boolean(hasErrorSearchTextField)}
          title="30文字以内で入力してください"
          placement="bottom-start"
        >
          <SearchTextField
            type="text"
            name={FormNameEnum.tenantCodeOrName}
            placeholder="テナント名/コード"
            maxLength={30}
            onClickClear={handleClickClear}
            className={style.textFieldWrapper}
          />
        </Tooltip>
        {/* NOTE: react-hook-formで値を管理すると値を変更したときに１ページ目に遷移してしまうためonChangeで値を管理する */}
        <Box className={style.checkContainer}>
          <Box className={style.ngCheck}>
            <FormControlLabel
              className={style.ngCheckText}
              control={
                <CheckBox
                  name={FormNameEnum.filterForMismatch}
                  defaultChecked={defaultFilterValues.filterForMismatch}
                  shape="rectangle"
                  onChange={handleChangeFilterForMismatch}
                />
              }
              label="不一致のみ表示"
            />
          </Box>
          <Typography
            className={style.label}
            variant="subtitle1"
            component="div"
          >
            ステータス
          </Typography>
          <PopupChild
            title={reportStatusTitle}
            placement="bottom-start"
            className={clsx(
              style.popupChild,
              reportStatusList.length > 0 && style.blue
            )}
          >
            <CheckBoxListWithFilter
              defaultValues={{
                allChecked: formInitialValues.reportStatus.allChecked,
                checkListValues: formInitialValues.reportStatus.checkListValues,
              }}
              checkList={statusCheckOptionsAfterReported}
              formName={FormNameEnum.reportStatus}
              disableSearch
            />
          </PopupChild>
        </Box>
        <Box className={style.checkContainer}>
          <Typography
            className={style.label}
            variant="subtitle1"
            component="span"
          >
            完全一致フラグ
          </Typography>
          <PopupChild
            title={consistencyTitle}
            placement="bottom-start"
            className={clsx(
              style.popupChild,
              consistencyList.length > 0 && style.blue
            )}
          >
            <CheckBoxListWithFilter
              defaultValues={{
                allChecked: formInitialValues.consistency.allChecked,
                checkListValues: formInitialValues.consistency.checkListValues,
              }}
              checkList={consistencyCheckOptions}
              formName={FormNameEnum.consistency}
              disableSearch
            />
          </PopupChild>
        </Box>
      </FormProvider>
      <Box className={style.checkContainer}>
        <Typography
          className={style.label}
          variant="subtitle1"
          component="span"
        >
          開始日
        </Typography>
        <DatePicker
          date={date.start}
          defaultDate={defaultFilterValues.startDate}
          maxDate={MAX_DATE}
          disableArrowIcon={false}
          onChange={handleChangeStartDate}
        />
      </Box>
      <Box className={style.checkContainer}>
        <Typography
          className={style.label}
          variant="subtitle1"
          component="span"
        >
          終了日
        </Typography>
        <DatePicker
          date={date.end}
          defaultDate={defaultFilterValues.endDate}
          maxDate={MAX_DATE}
          disableArrowIcon={false}
          onChange={handleChangeEndDate}
        />
      </Box>
    </Box>
  )
}

export default SalesReportsFilter
