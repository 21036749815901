import React, {
  ReactElement,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Path, { OrgPathParams } from 'src/routes/path'
import { useApiTenantOwnerCompany } from 'src/hooks/useApiTenantOwnerCompany'
import TemplatesTenantOwnerCompanyUpdate from 'src/features/tenantOwnerCompany/templates/Update'
import { TenantOwnerCompany } from 'src/slices/services/api'
import { ToastTriggerContext } from 'src/context/toast.context'
import { INFINITE_SCROLL_CONTENT_LOADING_NUM } from 'src/domain/config'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import { RowDataType } from '../../components/UpdateTableRow'
import { FilterParam } from '../../components/FilterBar'

type SearchParam = {
  searchText: string
  active?: boolean
  loadOffsetNum: number
}

const PagesTenantOwnerCompanyUpdate: React.FC = (): ReactElement => {
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()
  const { getTenantOwnerCompanies, updateTenantOwnerCompanies } =
    useApiTenantOwnerCompany()
  const [searchParam, setSearchParam] = useState<SearchParam>({
    searchText: '',
    active: undefined,
    loadOffsetNum: 0,
  })
  const [tenantOwnerCompanies, setTenantOwnerCompanies] = useState<
    TenantOwnerCompany[]
  >([])
  const [totalCount, setTotalCount] = useState<number>()
  const toastContext = useContext(ToastTriggerContext)
  useBeforeUnload()

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantOwnerCompanies(
        orgCode,
        searchParam.loadOffsetNum,
        INFINITE_SCROLL_CONTENT_LOADING_NUM,
        searchParam.searchText,
        searchParam.active
      )
      setTenantOwnerCompanies(res.data?.tenantOwnerCompanies || [])
      if (typeof res.data?.count === 'number') {
        setTotalCount(res.data?.count)
      }
    }
    executeApi()
  }, [getTenantOwnerCompanies, orgCode, searchParam])

  const handleChangeFilterParams = (filterData: FilterParam) => {
    setSearchParam({
      searchText: filterData.searchText || '',
      active: filterData.active,
      loadOffsetNum: 0,
    })
  }

  const handleSubmit = useCallback(
    async (data: RowDataType[]) => {
      const result = await updateTenantOwnerCompanies(orgCode, data)
      if (!result.success) {
        toastContext.sendToast({
          variant: 'error',
          title: result.message,
        })
        return
      }
      toastContext.sendToast({
        variant: 'success',
        title: '取引企業を追加しました',
      })
      navigate(Path.tenantOwnerCompanies(orgCode))
    },
    [navigate, orgCode, toastContext, updateTenantOwnerCompanies]
  )

  const handleGoBack = () => {
    navigate(Path.tenantOwnerCompanies(orgCode))
  }

  const handleLoadMore = (offset: number) => {
    setSearchParam({ ...searchParam, loadOffsetNum: offset })
  }

  return (
    <TemplatesTenantOwnerCompanyUpdate
      tenantOwnerCompanies={tenantOwnerCompanies}
      totalCount={totalCount}
      onChangeFilterParams={handleChangeFilterParams}
      onSubmit={handleSubmit}
      onGoBack={handleGoBack}
      loadMore={handleLoadMore}
    />
  )
}
export default PagesTenantOwnerCompanyUpdate
