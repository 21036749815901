import React from 'react'
import { Box, Table, TableBody } from '@mui/material'
import { AppTableHead } from 'src/components/tables'

import UpdateTableRow, {
  RowDataType,
  ValidateFormType,
} from '../UpdateTableRow'
import styles from './styles.module.scss'

export type UpdateTableProps = {
  shownTenantOwnerCompaniesFormData: RowDataType[]
  onChangeFormRowValue: (formData: RowDataType) => void
  onValidate: (validStatus: ValidateFormType) => void
} & React.StyledProps

const UpdateTable: React.StyledFC<UpdateTableProps> = ({
  shownTenantOwnerCompaniesFormData,
  onChangeFormRowValue,
  onValidate,
}: UpdateTableProps) => {
  return (
    <Box className={styles.root}>
      <Table stickyHeader>
        <AppTableHead
          columns={[
            {
              title: 'ステータス',
              width: 120,
            },
            {
              title: '企業コード',
              labeledMessage: '必須',
              message: '※ 重複不可',
              width: 180,
            },
            {
              title: '企業名称',
              labeledMessage: '必須',
              width: 380,
            },
          ]}
        />
        <TableBody>
          {shownTenantOwnerCompaniesFormData.map((formData) => {
            return (
              <UpdateTableRow
                key={formData.id}
                onChangeFormValue={onChangeFormRowValue}
                shownTenantOwnerCompanyFormData={formData}
                onValidate={onValidate}
              />
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

UpdateTable.displayName = 'UpdateTable'
export default UpdateTable
