import { findDuplicates } from '../../utils/array'
import { TenantOwnerCompanyCreateType } from './components/CreateTableRow'

type Payload = {
  formData?: TenantOwnerCompanyCreateType
  eachValidStatus?: { rowNum: number; isValid: boolean }
  removeRowNum?: number
}

export type Action = {
  type:
    | 'ADD_ROW'
    | 'REMOVE_ROW'
    | 'UPDATE_FORM_DATA'
    | 'UPDATE_EACH_VALID_STATUS'
    | 'SUBMIT_WITH_INVALID_FORM'
  payload?: Payload
}

export type TenantOwnerCompanyCreateState = {
  rows: number[]
  createdData: { [rowNum: number]: TenantOwnerCompanyCreateType }
  eachValidStatuses: { [rowNum: string]: boolean }
  duplicatedCodes: string[]
  isAllValid: boolean
  showAllError: boolean
}

export const INITIAL_STATE: TenantOwnerCompanyCreateState = {
  rows: [1],
  createdData: {},
  eachValidStatuses: {},
  duplicatedCodes: [],
  isAllValid: false,
  showAllError: false,
}

export const reducerFunc = (
  state: TenantOwnerCompanyCreateState,
  action: Action
): TenantOwnerCompanyCreateState => {
  let newState: TenantOwnerCompanyCreateState

  switch (action.type) {
    case 'ADD_ROW':
      if (state.rows.length > 0) {
        newState = {
          ...state,
          rows: [...state.rows, state.rows[state.rows.length - 1] + 1],
        }
      } else {
        newState = {
          ...state,
          rows: [0],
        }
      }
      break
    case 'REMOVE_ROW':
      if (typeof action.payload?.removeRowNum === 'number') {
        const newCreatedData = { ...state.createdData }
        delete newCreatedData[action.payload?.removeRowNum]
        const newEachValidStatuses = { ...state.eachValidStatuses }
        delete newEachValidStatuses[action.payload?.removeRowNum]
        newState = {
          ...state,
          createdData: newCreatedData,
          eachValidStatuses: newEachValidStatuses,
          rows: state.rows.filter(
            (row) => row !== action.payload?.removeRowNum
          ),
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'UPDATE_FORM_DATA':
      if (typeof action.payload?.formData?.rowNum === 'number') {
        newState = {
          ...state,
          createdData: {
            ...state.createdData,
            [action.payload?.formData?.rowNum]: action.payload?.formData,
          },
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'UPDATE_EACH_VALID_STATUS':
      if (action.payload?.eachValidStatus?.rowNum) {
        newState = {
          ...state,
          eachValidStatuses: {
            ...state.eachValidStatuses,
            [action.payload?.eachValidStatus?.rowNum]:
              action.payload?.eachValidStatus?.isValid,
          },
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'SUBMIT_WITH_INVALID_FORM':
      newState = {
        ...state,
        showAllError: true,
      }
      break
    default:
      newState = {
        ...state,
      }
  }
  newState.isAllValid = Object.values(newState.eachValidStatuses).every(
    (isValid) => isValid === true
  )
  const codes: string[] = Object.values(newState.createdData)
    .map((data) => data.code)
    .filter((code) => code !== undefined) as string[]
  newState.duplicatedCodes = findDuplicates<string>(codes)

  return newState
}
