import React, { useReducer, useEffect, useCallback, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { TenantOwnerCompany } from 'src/slices/services/api'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import Breadcrumbs, {
  BreadcrumbsProps,
} from 'src/components/molecules/Breadcrumbs'
import listStyles from 'src/styles/list-styles.module.scss'
import SmallGuide from 'src/components/molecules/SmallGuide'
import LoadMore from 'src/components/molecules/LoadMore'
import {
  TenantOwnerCompanyFilterBar,
  FilterParam,
} from '../../components/FilterBar'
import Footer from '../../components/Footer'
import ListTable from '../../components/ListTable'
import { reducerFunc, INITIAL_STATE } from '../../listReducer'
import styles from './styles.module.scss'

export type TemplatesTenantOwnerCompanyListProps = {
  tenantOwnerCompanies: TenantOwnerCompany[]
  totalCount?: number
  breadCrumbPaths: BreadcrumbsProps['paths']
  onChangeFilterParams: (filterData: FilterParam) => void
  onGoToCreate: () => void
  onGoToUpdate: () => void
  loadMore: (currentCount: number) => void
}

const TemplatesTenantOwnerCompanyList: React.StyledFC<
  TemplatesTenantOwnerCompanyListProps
> = ({
  tenantOwnerCompanies,
  totalCount,
  breadCrumbPaths,
  onChangeFilterParams,
  onGoToCreate,
  onGoToUpdate,
  loadMore,
}: TemplatesTenantOwnerCompanyListProps) => {
  const bottomBoundaryRef = useRef(null)
  const [listTenantOwnCompanyState, dispatch] = useReducer(
    reducerFunc,
    INITIAL_STATE
  )

  useEffect(() => {
    dispatch({
      type: 'ADD_NETWORK_DATA',
      payload: { networkData: tenantOwnerCompanies, totalCount },
    })
  }, [tenantOwnerCompanies, totalCount])

  const handleChangeFilterParams = (filterData: FilterParam) => {
    dispatch({ type: 'UPDATE_SEARCH_CONDITION', payload: { filterData } })
    onChangeFilterParams(filterData)
  }

  const handleOnReachToBottom = useCallback(() => {
    if (listTenantOwnCompanyState.hasMoreContent) {
      if (typeof listTenantOwnCompanyState.currentCount === 'number') {
        loadMore(listTenantOwnCompanyState.currentCount)
        dispatch({ type: 'LOAD_MORE' })
      }
    }
  }, [
    listTenantOwnCompanyState.currentCount,
    listTenantOwnCompanyState.hasMoreContent,
    loadMore,
  ])

  useEffect(() => {
    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        handleOnReachToBottom()
      }
    })
    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current)
    }
    return () => observer.disconnect()
  }, [handleOnReachToBottom])

  const renderContent = () => {
    if (listTenantOwnCompanyState.isLoading) {
      return (
        <Box className={styles.loadingContainer}>
          <CenteringLoading />
        </Box>
      )
    }

    if (totalCount === 0) {
      if (listTenantOwnCompanyState.hasFilterParam) {
        return (
          <SmallGuide
            icon="pray"
            className={styles.guide}
            message="該当の結果はありません"
            description="検索キーワードを確認して再度試してください"
          />
        )
      }
      return (
        <SmallGuide
          icon="bag"
          className={styles.guide}
          message="取引企業が登録されていません"
          description={
            '「企業新規追加」ボタンをクリックして、\n取引企業を追加してください'
          }
        />
      )
    }
    return (
      <>
        <ListTable
          tenantOwnerCompanies={listTenantOwnCompanyState.allShownData}
        />
        <div ref={bottomBoundaryRef} />
      </>
    )
  }

  return (
    <Box>
      <Box className={listStyles.container}>
        <Breadcrumbs className={styles.breadcrumb} paths={breadCrumbPaths} />
        <Typography className={styles.title} variant="h1">
          取引企業一覧
        </Typography>
        <Box className={styles.filterWrapper}>
          <Box className={styles.header}>
            <TenantOwnerCompanyFilterBar
              totalCount={listTenantOwnCompanyState.totalCount}
              onChangeFilterParams={handleChangeFilterParams}
            />
          </Box>
        </Box>
        <Box className={styles.mainContent}>
          {renderContent()}
          {listTenantOwnCompanyState.isLoadingMore && <LoadMore />}
        </Box>
      </Box>
      <Box className={styles.bottom}>
        <Footer
          onGoToCreate={onGoToCreate}
          onGoToUpdate={onGoToUpdate}
          disableGoToUpdateButton={
            listTenantOwnCompanyState?.totalCount === undefined ||
            listTenantOwnCompanyState.totalCount === 0
          }
        />
      </Box>
    </Box>
  )
}

export default TemplatesTenantOwnerCompanyList
