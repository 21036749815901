import React from 'react'
import { Tabs as MaterialTabs, Tab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import color from '../../../styles/color'

export type TabOption = {
  title: string
  index: number
}

export type TabsProps = {
  options: TabOption[]
  selectedIndex: number
  onClick?: (index: number) => void
} & React.StyledProps

const useStyles = makeStyles({
  tab: {
    minWidth: 'auto',
    padding: 0,
    marginRight: 32,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    opacity: 'unset',
    '&:last-child': {
      marginRight: 'unset',
    },
    '&.Mui-selected': {
      color: color.primary,
      fontWeight: 700,
    },

    '@media (width <= 1280px)': {
      fontSize: 14,
    },
  },
  indicator: {
    height: 3,
  },
})

const Tabs: React.StyledFC<TabsProps> = ({
  className,
  options,
  selectedIndex,
  onClick,
}: TabsProps) => {
  const classes = useStyles()

  return (
    <MaterialTabs
      value={selectedIndex}
      indicatorColor="primary"
      classes={{
        root: className,
        indicator: classes.indicator,
      }}
      onChange={(_, index) => onClick && onClick(index)}
    >
      {options.map((tab) => {
        return (
          <Tab
            classes={{ root: classes.tab }}
            key={tab.index}
            label={tab.title}
            value={tab.index}
          />
        )
      })}
    </MaterialTabs>
  )
}

export default Tabs
