import { TenantOwnerCompany } from 'src/slices/services/api'
import { RowDataType } from './components/UpdateTableRow'

type Payload = {
  networkData?: TenantOwnerCompany[]
  formData?: RowDataType
  eachValidStatus?: { id: string; isValid: boolean }
  totalCount?: number
}

export type Action = {
  type:
    | 'ADD_NETWORK_DATA'
    | 'UPDATE_SEARCH_CONDITION'
    | 'DELETE_NETWORK_DATA'
    | 'UPDATE_FORM_DATA'
    | 'UPDATE_EACH_VALID_STATUS'
    | 'LOAD_MORE'
    | 'SHOW_UPDATED'
    | 'SHOW_ALL'
  payload?: Payload
}

export type TenantOwnerCompanyUpdateState = {
  networkData: TenantOwnerCompany[]
  shownFormData: RowDataType[]
  isLoading: boolean
  isLoadingMore: boolean
  currentCount: number
  hasMoreContent: boolean
  isAllValid: boolean
  showUpdated: boolean
  totalCount: number
  eachValidStatuses: { [id: string]: boolean }
  formUpdatedData: {
    [id: string]: { new: RowDataType; original: TenantOwnerCompany }
  }
}

export const INITIAL_STATE: TenantOwnerCompanyUpdateState = {
  networkData: [],
  shownFormData: [],
  isLoading: true,
  isLoadingMore: false,
  currentCount: 0,
  hasMoreContent: false,
  isAllValid: false,
  showUpdated: false,
  totalCount: 0,
  eachValidStatuses: {},
  formUpdatedData: {},
}

export const reducerFunc = (
  state: TenantOwnerCompanyUpdateState,
  action: Action
): TenantOwnerCompanyUpdateState => {
  let newState: TenantOwnerCompanyUpdateState
  switch (action.type) {
    case 'ADD_NETWORK_DATA':
      {
        const originalContentCount = action.payload?.networkData?.length
        const addedData =
          action.payload?.networkData?.filter((data) => {
            return !state.networkData.find((d) => d.id === data.id)
          }) || []
        let nextCurrentCount = state.currentCount
        if (addedData.length > 0 && typeof originalContentCount === 'number') {
          nextCurrentCount += originalContentCount
        }
        const totalCount = action.payload?.totalCount
        let hasMoreContent = false
        if (totalCount && nextCurrentCount < totalCount) {
          hasMoreContent = true
        }
        newState = {
          ...state,
          networkData: [...state.networkData, ...addedData],
          currentCount: nextCurrentCount,
          hasMoreContent,
          isLoading: false,
          isLoadingMore: false,
          totalCount: totalCount || 0,
        }
      }
      break
    case 'UPDATE_SEARCH_CONDITION':
      newState = {
        ...state,
        networkData: [],
        isLoading: true,
        totalCount: 0,
        currentCount: 0,
        hasMoreContent: false,
      }
      break
    case 'UPDATE_FORM_DATA':
      if (action.payload?.formData) {
        const { formData } = action.payload
        const targetId = formData?.id
        const currentTargetData = state.formUpdatedData[targetId]
        if (currentTargetData) {
          const { original } = currentTargetData

          if (
            original.active === formData.active &&
            original.code === formData.code &&
            original.name === formData.name
          ) {
            const newFormUpdatedData = { ...state.formUpdatedData }
            const newEachValidStatuses = { ...state.eachValidStatuses }
            delete newFormUpdatedData[targetId]
            delete newEachValidStatuses[targetId]
            newState = {
              ...state,
              formUpdatedData: newFormUpdatedData,
              eachValidStatuses: newEachValidStatuses,
            }
          } else {
            newState = {
              ...state,
              formUpdatedData: {
                ...state.formUpdatedData,
                [targetId]: {
                  original,
                  new: formData,
                },
              },
            }
          }
        } else {
          const foundNetworkData = state.networkData.find(
            (data) => data.id === targetId
          )
          // Note: 表示されているものを変更しているのでfindみつからないことは実際はない。
          if (!foundNetworkData) {
            newState = {
              ...state,
            }
          } else {
            const newData = { original: foundNetworkData, new: formData }
            newState = {
              ...state,
              formUpdatedData: {
                ...state.formUpdatedData,
                [targetId]: newData,
              },
            }
          }
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'UPDATE_EACH_VALID_STATUS':
      if (action.payload?.eachValidStatus?.id) {
        newState = {
          ...state,
          eachValidStatuses: {
            ...state.eachValidStatuses,
            [action.payload?.eachValidStatus?.id]:
              action.payload?.eachValidStatus?.isValid,
          },
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'SHOW_UPDATED':
      newState = {
        ...state,
        showUpdated: true,
      }
      break
    case 'SHOW_ALL':
      newState = {
        ...state,
        showUpdated: false,
      }
      break
    case 'LOAD_MORE':
      newState = {
        ...state,
        isLoadingMore: true,
      }
      break
    default:
      newState = {
        ...state,
      }
  }

  if (newState.showUpdated) {
    newState.shownFormData = Object.keys(newState.formUpdatedData).map(
      (key) => {
        return newState.formUpdatedData[key].new
      }
    )
  } else {
    newState.shownFormData = newState.networkData.map((data) => {
      if (newState.formUpdatedData[data.id]) {
        return newState.formUpdatedData[data.id].new
      }
      return {
        id: data.id,
        name: data.name,
        code: data.code,
        active: data.active,
      }
    })
  }
  newState.isAllValid = Object.values(newState.eachValidStatuses).every(
    (isValid) => isValid === true
  )
  return newState
}
