import React, { useEffect, useState } from 'react'
import { useForm, FormProvider, useFormState } from 'react-hook-form'
import {
  InvalidInputErrorMessage,
  RegexAlphanumeric,
} from 'src/utils/regularExpression'
import { AppTableRow, InputCell, IconCell } from 'src/components/tables'
import { FormNameEnum } from '../../type'

export interface TenantOwnerCompanyFormData {
  [FormNameEnum.Code]?: string
  [FormNameEnum.Name]?: string
}
export interface TenantOwnerCompanyCreateType
  extends TenantOwnerCompanyFormData {
  rowNum: number
}
export type ValidateFormTypeCreate = { isValid: boolean; rowNum: number }

export type CreateTableRowProps = {
  rowNum: number
  showAllError: boolean
  onRemoveRow: (rowNum: number) => void
  onChangeFormValue: (formData: TenantOwnerCompanyCreateType) => void
  onValidate: (validStatus: ValidateFormTypeCreate) => void
} & React.StyledProps

const CreateTableRow: React.StyledFC<CreateTableRowProps> = ({
  onChangeFormValue,
  onValidate,
  onRemoveRow,
  rowNum,
  showAllError,
}: CreateTableRowProps) => {
  const formMethods = useForm<TenantOwnerCompanyFormData>({ mode: 'onChange' })
  const { isValid } = useFormState({ control: formMethods.control })
  const [showErrorCode, setShowErrorCode] = useState(false)
  const [showErrorName, setShowErrorName] = useState(false)

  useEffect(() => {
    onValidate({ isValid, rowNum })
  }, [isValid, onValidate, rowNum])

  useEffect(() => {
    formMethods.trigger()
  }, [formMethods])

  useEffect(() => {
    if (showAllError) {
      setShowErrorCode(true)
      setShowErrorName(true)
    }
  }, [showAllError])

  useEffect(() => {
    const subscription = formMethods.watch(
      (value: TenantOwnerCompanyFormData, { name }) => {
        if (name === FormNameEnum.Code) {
          setShowErrorCode(true)
        }
        if (name === FormNameEnum.Name) {
          setShowErrorName(true)
        }

        onChangeFormValue({ ...value, rowNum })
      }
    )
    return () => subscription.unsubscribe()
  }, [formMethods, onChangeFormValue, rowNum])

  return (
    <AppTableRow>
      <FormProvider {...formMethods}>
        <IconCell icon="minus" onClick={() => onRemoveRow(rowNum)} />
        <InputCell
          name={FormNameEnum.Code}
          required
          minLength={1}
          width={180}
          validations={[
            {
              regex: RegexAlphanumeric,
              message: InvalidInputErrorMessage,
            },
          ]}
          hideError={!showErrorCode}
        />
        <InputCell
          required
          minLength={1}
          name={FormNameEnum.Name}
          width={360}
          hideError={!showErrorName}
        />
      </FormProvider>
    </AppTableRow>
  )
}

CreateTableRow.displayName = 'CreateTableRow'
export default CreateTableRow
