import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import SearchTextField from 'src/components/molecules/SearchTextField'
import SelectWithOption, {
  Option,
} from 'src/components/molecules/SelectWithOption'
import styles from './styles.module.scss'

export type ActiveTypeString = 'all' | 'active' | 'inactive'

export type FilterParam = {
  active?: boolean
  searchText?: string
}
export type FilterFormType = {
  active?: ActiveTypeString
  searchText?: string
}
export const InitialFilterParm: FilterFormType = {
  active: 'all',
  searchText: '',
}

const options: Option[] = [
  { title: 'すべてのステータス', value: 'all' },
  { title: '利用中', value: 'active' },
  { title: '利用停止', value: 'inactive' },
]

export type TenantOwnerCompanyFilterBarProps = {
  onChangeFilterParams: (filterData: FilterParam) => void
  totalCount?: number
} & React.StyledProps

export const TenantOwnerCompanyFilterBar: React.StyledFC<
  TenantOwnerCompanyFilterBarProps
> = ({
  onChangeFilterParams,
  totalCount,
}: TenantOwnerCompanyFilterBarProps) => {
  const formMethods = useForm<FilterFormType>({
    defaultValues: InitialFilterParm,
  })

  const adjustFormParam = (formData: FilterFormType): FilterParam => {
    let activeStatus: boolean | undefined

    switch (formData.active) {
      case 'active':
        activeStatus = true
        break
      case 'inactive':
        activeStatus = false
        break
      default:
    }
    return { ...formData, active: activeStatus }
  }

  const applyFormData = (formData: FilterFormType) => {
    const adjustedParm = adjustFormParam(formData)
    onChangeFilterParams(adjustedParm)
  }

  const handleClickClear = () => {
    formMethods.reset({ searchText: '' })
    const formData = formMethods.getValues()
    formData.searchText = ''
    applyFormData(formData)
  }

  const handleChangeFormValue = (text: string) => {
    const formData = formMethods.getValues()
    formData.searchText = text
    applyFormData(formData)
  }

  const handleChangeOption = (option: Option) => {
    const formData = formMethods.getValues()
    formData.active = option.value as ActiveTypeString
    applyFormData(formData)
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <Box className={styles.container}>
          <Box className={styles.item}>
            <span className={styles.total}>{totalCount}</span>
            <span>社</span>
          </Box>
          <Box className={styles.item}>
            <SearchTextField
              name="searchText"
              type="text"
              placeholder="企業コード or 企業名称"
              className={styles.search}
              onClickClear={handleClickClear}
              onChange={handleChangeFormValue}
            />
          </Box>
          <Box className={styles.item}>
            <SelectWithOption
              name="active"
              options={options}
              placeholder="利用ステータス"
              variant="standard"
              onChange={handleChangeOption}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
