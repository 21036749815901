import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import clsx from 'clsx'
import TenantFilterBar, { FilterForm } from '../../organisms/TenantFilterBar'
import { TenantStatusEnum } from '../../../slices/services/api'
import TenantUpdateTable, {
  TenantUpdateTableProps,
  TenantFormType,
} from '../../organisms/TenantUpdateTable'
import styles from './styles.module.scss'
import Button from '../../atoms/Button'
import Modal from '../../molecules/Modal'
import {
  AGREE_BUTTON,
  BACK_BUTTON,
  CHANCEL_BUTTON,
  DESCRIPTION,
  MODAL_TITLE,
  SUBMIT_BUTTON,
  TITLE,
} from './constants'
import Loading from '../Loading'
import { InitialTenantState, reducerFunc } from '../../../utils/tenantReducer'

export type TenantUpdateProps = {
  orgCode: string
  storeName: string
  storeCode: string
  loading?: boolean
  onClickBack: () => void
  onSubmit: (values: TenantFormType) => void
} & Pick<TenantUpdateTableProps, 'tenants'>

const TenantUpdate: React.StyledFC<TenantUpdateProps> = ({
  orgCode,
  tenants,
  storeName,
  storeCode,
  loading,
  onClickBack,
  onSubmit,
}: TenantUpdateProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [tenantState, dispatch] = useReducer(
    reducerFunc,
    InitialTenantState(tenants),
    (init) => init
  )
  useEffect(() => {
    dispatch({ type: 'UPDATE_TENANTS', payload: { allTenants: tenants } })
  }, [tenants])

  const filterMethods = useForm<FilterForm>()
  const { watch: filterWatch } = filterMethods
  const filterWatcher = filterWatch()
  useEffect(() => {
    const filterStatus: TenantStatusEnum[] = []
    if (filterWatcher.notReady) filterStatus.push(TenantStatusEnum.NotReady)
    if (filterWatcher.ready) filterStatus.push(TenantStatusEnum.Ready)
    if (filterWatcher.closed) filterStatus.push(TenantStatusEnum.Closed)

    dispatch({
      type: 'UPDATE_FILTERED_TENANTS',
      payload: {
        filterStatus,
        searchParamText: filterWatcher.tenantSearchText,
      },
    })
  }, [
    filterWatcher.notReady,
    filterWatcher.closed,
    filterWatcher.ready,
    filterWatcher.tenantSearchText,
  ])

  const tenantFormMethods = useForm<TenantFormType>()
  const { watch: tenantWatch } = tenantFormMethods
  const tenantWatcher = tenantWatch()

  const validate = useCallback((): boolean => {
    const values = tenantFormMethods.getValues().tenants
    if (!values) return false

    if (Object.entries(tenantFormMethods.formState.errors).length !== 0) {
      return false
    }

    const unique = new Set(values.map((t) => t.code))
    if (unique.size !== values.length) {
      return false
    }

    return true
  }, [tenantFormMethods])

  const [disabledSubmit, setDisabledSubmit] = useState(true)

  useEffect(() => {
    const isValid = validate()
    setDisabledSubmit(!isValid)
  }, [tenantWatcher, validate])

  const handleClickAgree = () => {
    onClickBack()
    setModalOpen(false)
  }

  const handleSubmit = async (values: TenantFormType) => {
    const mappedValues = {
      tenants: values.tenants.map((t) => ({
        ...t,
        status: t.status || TenantStatusEnum.NotReady,
      })),
    }

    onSubmit(mappedValues)
  }

  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.header}>
          <Box className={styles.title}>
            <Typography variant="h1">{TITLE}</Typography>
            <Box className={styles.subTitle}>
              <Typography
                variant="h2"
                className={clsx(styles.item, styles.code)}
              >
                {storeCode}
              </Typography>
              <Typography variant="h2" className={styles.item}>
                {storeName}
              </Typography>
            </Box>
            <FormProvider {...filterMethods}>
              <form>
                <TenantFilterBar
                  className={styles.filterBar}
                  tenantCount={tenantState.filteredTenants.length}
                />
              </form>
            </FormProvider>
          </Box>
          <Box className={styles.description}>
            <Typography variant="body2">{DESCRIPTION}</Typography>
          </Box>
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <FormProvider {...tenantFormMethods}>
            <form onSubmit={tenantFormMethods.handleSubmit(handleSubmit)}>
              <Box className={styles.main}>
                <TenantUpdateTable
                  tenants={tenantState.filteredTenants}
                  orgCode={orgCode}
                />
              </Box>
              <Box className={styles.footer}>
                <Box className={styles.buttonWrapper}>
                  <Button
                    type="button"
                    className={styles.button}
                    color="secondary"
                    size="medium"
                    title={BACK_BUTTON}
                    onClick={() => setModalOpen(true)}
                  />
                  <Button
                    type="submit"
                    className={styles.button}
                    color="primary"
                    size="medium"
                    title={SUBMIT_BUTTON}
                    disabled={disabledSubmit}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
        )}
      </Box>
      <Modal
        open={modalOpen}
        title={MODAL_TITLE}
        agreeButtonTitle={AGREE_BUTTON}
        disagreeButtonTitle={CHANCEL_BUTTON}
        onClickAgree={handleClickAgree}
        onClickDisagree={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default TenantUpdate
