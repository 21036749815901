import React, { ReactElement, useContext, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Path, { OrgPathParams } from 'src/routes/path'
import { useApiTenantOwnerCompany } from 'src/hooks/useApiTenantOwnerCompany'
import { TenantOwnerCompanyPostRequest } from 'src/slices/services/api'
import { ToastTriggerContext } from 'src/context/toast.context'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import TemplatesTenantOwnerCompanyCreate from '../../templates/Create'

const PagesTenantOwnerCompanyCreate: React.FC = (): ReactElement => {
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()
  const { createTenantOwnerCompanies } = useApiTenantOwnerCompany()
  const toastContext = useContext(ToastTriggerContext)
  useBeforeUnload()

  const handleSubmit = useCallback(
    async (data: TenantOwnerCompanyPostRequest[]) => {
      const result = await createTenantOwnerCompanies(orgCode, data)
      if (!result.success) {
        toastContext.sendToast({
          variant: 'error',
          title: result.message,
        })
        return
      }
      toastContext.sendToast({
        variant: 'success',
        title: '取引企業を追加しました',
      })
      navigate(Path.tenantOwnerCompanies(orgCode))
    },
    [createTenantOwnerCompanies, navigate, orgCode, toastContext]
  )

  const handleGoBack = () => {
    navigate(Path.tenantOwnerCompanies(orgCode))
  }

  return (
    <TemplatesTenantOwnerCompanyCreate
      onSubmit={handleSubmit}
      onGoBack={handleGoBack}
    />
  )
}

export default PagesTenantOwnerCompanyCreate
