import { MemoType, UpdateStatusType } from './type'

const memoMap = {
  reportMemo: '補足事項',
  manageMemo: '管理者メモ',
}

export const getMemoTitle = (memo?: MemoType): string => {
  return memo ? memoMap[memo] ?? '' : ''
}

export const memoCheckOptions: { label: string; value: MemoType }[] = [
  { label: getMemoTitle('reportMemo'), value: 'reportMemo' },
  { label: getMemoTitle('manageMemo'), value: 'manageMemo' },
]

export const popupOptions: UpdateStatusType[] = [
  'revertReported',
  'bulkFinalize',
]
